.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.label {
  font-size: calc(16px + 2vmin)
}

.deck-choice {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.deck-choice-title {
  align-self: center;
  flex-basis: 80%;
}

.break {
  flex-basis: 100%;
  height: 0;
}
