.card {
    display: inline-block;
    width: 4em;
    height:  5.6em;
    border: 1px solid #666;
    border-radius: .3em;
    -moz-border-radius: .3em;
    -webkit-border-radius: .3em;
    -khtml-border-radius: .3em;
    padding: .25em;
    margin: 0 .5em .5em 0;
    text-align: center;
    font-size: 1.5em; /* @change: adjust this value to make bigger or smaller cards */
    font-weight: normal;
    font-family: Arial, sans-serif;
    position: relative;
    background-color: #fff;
    -moz-box-shadow: .2em .2em .5em #333;
    -webkit-box-shadow: .2em .2em .5em #333;
    box-shadow: .2em .2em .5em #333;
    overflow: scroll;
}

.card.dialog {
    width: 7em;
    height:  9.8em;
}

.card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-button-area {
    display: flex;
    flex-direction: column;
}

a.card {
    text-decoration: none;
}

/* selected and hover state */
.card:hover, card:active, card:focus,
strong .card {
    box-shadow: 0px 0px 0.7em 0.1em lightskyblue;
}

/* selected and hover state */
.card.hand:hover, .card.battlefield:hover, card:active, card:focus,
strong .card.hand{
    box-shadow: 0px 0px 0.4em 0em rgb(255, 239, 92);
    bottom: 1.6em;
    font-size: 2em;
    transform: scale(1.5);
    z-index: 50;
}

.card.back {
    background-color: #ccc;
    background-repeat: repeat;
    background-image: -webkit-gradient(radial, center center, 20, center center, 80, from(#3c3), color-stop(0.4, #363), to(#030));
    display: flex;
    align-items: center;
    justify-content: center;
}

.deck {
    cursor:pointer;
}

.battlefield-container {
    position: absolute;
    top: 15%;
    left: 5vw;
}

.deck-container {
    position: absolute;
    bottom: 1vh;
    right: 1vw;
}

.discard-container {
    position: absolute;
    bottom: 50vh;
    right: 1vw;
}

.hand-container {
    background-color: tan;
    position: absolute;
    bottom: 1vh;
    left: 2vw;
    border-radius: 10px;
    padding: 0.5em 0em 0em 0.5em;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: center;
    width: 70vw;
    min-height: 32vh;
}

.hand-container-label {
    position: absolute;
    bottom: 35vh;
    left: 2vw;
}